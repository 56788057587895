<template>
  <!-- 啟動遊戲 -->
  <div
    id="lauch-game"
    :class="[!isLiffMode ? 'pt-56' : '']"
  >
    <!-- 隱藏 toolbar -->
    <v-btn
      v-show="showMiniBtn"
      class="show-toolbar-btn"
      icon
      color="primary"
      @click="showMiniIcon(false)"
    >
      <Icon
        data="@icon/minimize.svg"
        width="36"
        height="36"
        class="icon--text"
      />
    </v-btn>

    <!-- toolbar -->
    <Toolbar v-show="!isLiffMode">
      <v-btn
        slot="btn"
        text
        class="title--text"
        @click="showMiniIcon(true)"
      >
        {{ $t('global.fullScreen') }}
      </v-btn>
    </Toolbar>

    <!-- 遊戲畫面 -->
    <div class="lauch h-100dvh over-flow-y-scroll">
      <iframe
        frameborder="0"
        :src="gameUrl"
        height="100%"
        width="100%"
      />
    </div>
  </div>
</template>

<script>
import Toolbar from '@/components/base/Toolbar.vue'

import { mapActions, mapGetters } from 'vuex'
import { resStatus } from '@/utils/resUtils'

export default {
  components: {
    Toolbar,
  },

  data: () => ({
    gameUrl: '',
    showToolbar: true,
    showMiniBtn: false,
  }),

  computed: {
    // 來自 api 的動態顯示 toolbar
    showToolbarFromAPI() {
      return true
    },

    ...mapGetters(['isLiffMode']),
  },

  created() {
    this.set_progress_status(true)
    if (this.$route.query.url) {
      // LIFF MODE 嵌入遊戲
      this.getGameUrlSuccess(this.$route.query.url)
    } else {
      this.getGameUrl({ gamecode: this.$route.query.t })
    }
  },

  mounted() {
    this.showToolbar = this.showToolbarFromAPI
  },

  methods: {
    ...mapActions([
      'get_game_url',
      'show_alert',
      'set_progress_status',
      'get_game_url_by_device',
    ]),

    /**
     * 取得遊戲 url (成功)
     * @date 2021-07-02
     * @param {string} gameUrl 遊戲連結 url
     */
    getGameUrlSuccess(gameUrl) {
      this.$log(`game url: ${gameUrl}`)
      this.gameUrl = gameUrl
      this.set_progress_status(false)
    },

    /**
     * 取得遊戲 url (失敗)
     * @date 2021-07-02
     */
    getGameUrlFail() {
      this.set_progress_status(false)
      this.show_alert({ icon: 'fail' })
      this.$router.replace({ path: '/' })
    },

    /**
     * 取得遊戲 url
     * @date 2021-07-02
     * @param {object} gameCode 遊戲代碼
     */
    async getGameUrl(gameCode) {
      const res = await this.get_game_url_by_device(gameCode)
      resStatus(res, this.getGameUrlSuccess, this.getGameUrlFail)
    },

    showMiniIcon(status) {
      this.showMiniBtn = status
      this.showToolbar = !status
    },
  },
}
</script>

<style lang="scss" scoped>
#lauch-game {
	position: relative;

	.show-toolbar-btn {
		position: absolute;
		top: 5px;
		right: 5px;
	}
}
</style>
