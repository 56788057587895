<template>
  <div class="navbar d-flex align-center justify-center">
    <div class="navbar__language">
      <div class="global d-flex align-center">
        <!-- 地球 Icon -->
        <Icon
          :data="iconGlobal"
          width="24px"
          height="24px"
          @click="languageListVisible = !languageListVisible"
        />
      </div>

      <!-- 語系清單 -->
      <div
        v-if="languageListVisible"
        class="navbar__language__list d-flex flex-column align-center py-2 bg"
      >
        <div
          v-for="lang in switchLanguages"
          :key="lang.code"
          class="navbar__language__list__item d-flex justify-center align-center my-1 py-1"
          :class="{'active': lang.code === activeLang}"
          @click="settingClientLang(lang.code)"
        >
          <img
            :src="baseImgUrl+lang.icon"
            :alt="lang.default_name"
            width="20px"
            height="20px"
          >
        </div>
      </div>
    </div>

    <!-- 中間 Logo 圖  -->
    <img
      :src="baseImgUrl+websiteLogo"
      :alt="websiteName"
      class="navbar__logo"
    >
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import iconGlobal from '@/assets/icons/global.svg'

export default {
  name: 'Navbar',
  data() {
    return {
      iconGlobal,
      languageListVisible: false, // 顯示語系下拉選單
      activeLang: null, // 當前語系
    }
  },
  computed: {
    ...mapGetters([
      'clientLang', // 前端當前切換語系
      'baseImgUrl',
      'websiteName']),
    ...mapState({
    // 可選語言
      switchLanguages: state => state.websiteData.lang_available,
      websiteLogo: state => state.websiteData.site_params?.logo_dark,
    }),
  },
  methods: {
    ...mapActions([
      'get_website_theme',
      'get_homepage_layout_area',
    ]),
    // 設置前端語系
    settingClientLang(clientLang) {
      if (clientLang === this.clientLang) return false

      this.$i18n.locale = clientLang
      sessionStorage.setItem('clientLang', clientLang)
      this.activeLang = clientLang

      // 更新 layout 資料
      this.get_website_theme()
      this.get_homepage_layout_area()
    },
  },
}
</script>

<style lang="scss" scoped>
.navbar {
  height: 52px;
  position: relative;

  &__language {
    position: absolute;
    left: 10px;

    .global{
      cursor: pointer;
      &::after {
        display: block;
        content: '';
        width: 0;
        height: 0;
        margin-left: 5px;
        border-style: solid;
        border-width: 6px 5px 0 5px;
        border-color: #F87248 transparent transparent transparent;
      }
    }

    &__list {
      position: absolute;
      width: 45px;
      border-radius: 10px;
      z-index: 999;

      &__item {
        width: 100%;
        cursor: pointer;

        img {
          object-fit: contain;
        }

        &.active {
          background: rgba(#9F8AFF, .3);
        }
      }
    }

  }

  &__logo {
    width: 150px;
    height: 40px;
    object-fit: contain;
  }
}
</style>
