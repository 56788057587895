<!-- 已廢棄 -->
<template>
  <div>
    <component :is="layoutTemplate" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { curryTemplate } from '../../utils/template'

import template1 from './template/ToolbarTemplate1.vue'
import template3 from './template/ToolbarTemplate3.vue'
import template4 from './template/ToolbarTemplate4.vue'

export default {
  computed: {
    ...mapGetters(['layout']),

    layoutTemplate() {
      const templateObj = {
        template1,
        template3,
        template4,
      }
      return curryTemplate(this.layout, templateObj)
    },
  },
}
</script>

<style lang="scss" scoped></style>
