import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    color: {
      type: String,
      required: false,
    },
  },

  computed: {
    ...mapState({
      title: state => state.toolbar.title,
    }),
    ...mapGetters(['theme']),
    bgClr() {
      return this.$vuetify.theme.themes[this.theme]?.navBar
    },
  },

  destroyed() {
    this.set_toolbar_title('')
  },

  methods: {
    ...mapActions(['set_toolbar_title']),
    goBack() {
      this.$log(`location: ${window.location}`)
      if (this.$route.name === 'launchGame') {
        this.$route.query.redirectUrl
          ? this.$router.replace({ path: this.$route.query.redirectUrl })
          : this.goBackIfNoHistory()

        return false
      }
      this.goBackIfNoHistory()
    },

    goBackIfNoHistory() {
      if (window.history.length < 2) return this.$router.push({ path: '/' })
      if (this.$route.name === 'slot') return this.$router.push({ path: '/' })
      this.$router.go(-1)
    },
  },

}
