import axios from 'axios'
import store from '@/store'
import router from '@/router'
import { getCookie } from '../utils/cookie'
import Vue from '../main'
import isLocalDev from '../utils/isLocalDev'
import { detectDevIsLineLiffMode } from '../utils/liff'

/*
  發出每個請求前，如果有accessToken，會在Header加上 Bearer + accessToken
  會員登入後會取得accessToken
  accessToken是後端用來辨識是哪一個會員
*/

/* -------------訪api資料實體------------------ */
const request = axios.create({
  baseURL: isLocalDev() ? '/api' : process.env.VUE_APP_API,
  timeout: 50000, // request timeout
})

/* ------------- 取得雷火 實體 ------------------ */
export const gameEvent = axios.create({
  baseURL: process.env.VUE_APP_TFG_API,
  headers: {
    Authorization: process.env.VUE_APP_TFG_AUTH,
  },
  timeout: 50000, // request timeout
})

// 請求處理預處理
request.interceptors.request.use(
  config => {
    /* wtoken是後端用來辨識是哪一間營運商，一定要在headers加上wtoken */
    if (sessionStorage.getItem('wtoken')) {
      config.headers.wtoken = sessionStorage.getItem('wtoken')
    }

    // 如果有登入過，加入使用者 Authorization
    if (getCookie('accessToken')) { config.headers.Authorization = 'Bearer ' + getCookie('accessToken') }

    // 如果前端語系有切換語系，帶入 lang 值
    const clientLang = sessionStorage.getItem('clientLang')
    if (clientLang) config.headers.lang = clientLang

    return config
  },
  error => {
    Vue.$error(error)
    return Promise.reject(error)
  },
)

// 響應預處理
request.interceptors.response.use(
  response => {
    store.dispatch('set_status_error_code', null)

    /* 開發模式，確認 res */

    Vue.$log(
      `%c✅ Response:%c${response.config.url}`,
      'background: deepskyblue; padding: 3px;',
      'padding: 3px;',
      response.data,
    )

    const data = response.data
    const { errors } = data
    // store.state.errorCode = response.status

    /* 如果 status: '0' 有錯誤信息 status === '0' */
    if (errors) {
      Vue.$error('Response Fail:' + errors)

      // 系統維護中
      if (errors[0] === 'System is under maintenance') {
        store.dispatch('set_status_error_code', 503)
        return router.push({ name: '503' })
      }

      // token 失效
      const tokenExpired = ['认证失败', 'Token is expired', 'Authorization token not found', 'Token is invalid']
      // 认证失败
      const isTokenExpired = tokenExpired.find(errorItem => errors[0].includes(errorItem))

      if (!detectDevIsLineLiffMode() || !window.liff.isInClient()) {
      // accToken 過期或失效
        if (isTokenExpired) {
          store.dispatch('reset_user_data')

          if (router.currentRoute.name === 'qrcode') return false

          // 請重新登入
          store.dispatch('show_alert', {
            icon: 'fail',
            text: Vue.$t('subHeader.reLogin'),
          })

          if (router.currentRoute.name === 'login') return false

          setTimeout(() => {
            Vue.$router.push({ name: 'login' }).catch(() => {})
          }, 1000)
        }
      }
    }

    return data
  },
  error => {
    const statusCode = error.response.status

    if (statusCode === 500) {
      store.dispatch('set_status_error_code', statusCode)
      router.push({ name: '500' })
    }

    if (statusCode === 503) {
      store.dispatch('set_status_error_code', statusCode)
      router.push({ name: '503' })
    }

    Vue.$error('DEBUG: ⛔ 請求發生錯誤：' + error) // for debug
    return Promise.reject(error)
  },
)

export default request
