<template>
  <div
    class="h-100dvh over-flow-y-scroll pt-56"
    :class="{ 'popupbg': layout === 'template3' }"
  >
    <v-container>
      <v-row v-if="bonusNews">
        <v-col class="px-0 py-0 p-relative bonus__item">
          <v-img
            class="rounded"
            :src="baseImgUrl + bonusNews.content_image"
            height="100%"
            cover
          >
            <!-- lazy progress -->
            <template #placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                />
              </v-row>
            </template>
          </v-img>

          <!-- 簽到 (每日) -->
          <v-btn
            v-if="checkBtn.dailycheckin === 'Y'"
            fixed
            :rounded="layout !== 'template3'"
            :outlined="layout === 'template3'"
            width="160px"
            class="bonus__daily-checkin"
            color="secondary"
            @click="getBonusApply(bonusNews.promo_id, 'apply')"
          >
            {{ $t('global.dailyCheckin') }}
          </v-btn>

          <!-- 已簽到 (每日) -->
          <v-btn
            v-if="checkBtn.dailycheckin === 'DONE'"
            fixed
            rounded
            width="160px"
            class="bonus__daily-checkin opacity-1"
            color="secondary"
          >
            {{ $t('global.dailyCheckined') }}
          </v-btn>

          <!-- 我要參加 'Y' (每月) -->
          <v-btn
            v-if="checkBtn.monthly_dailycheckin === 'Y'"
            fixed
            :rounded="layout !== 'template3'"
            :outlined="layout === 'template3'"
            width="160px"
            class="bonus__item-apply"
            color="secondary"
            @click="getBonusApply(bonusNews.promo_id, 'apply')"
          >
            {{ $t('global.join') }}
          </v-btn>

          <!-- 我要領取 'DONE' (每月) -->
          <v-btn
            v-if="checkBtn.monthly_dailycheckin === 'DONE'"
            fixed
            :rounded="layout !== 'template3'"
            :outlined="layout === 'template3'"
            width="160px"
            class="bonus__item-apply"
            color="secondary"
            @click="monthlyBonusReceive(bonusNews.promo_id)"
          >
            {{ $t('global.getBonus') }}
          </v-btn>

          <!-- 我要參加 -->
          <v-btn
            v-if="checkBtn.apply === 'Y'"
            fixed
            :rounded="layout !== 'template3'"
            :outlined="layout === 'template3'"
            width="160px"
            class="bonus__item-apply"
            color="secondary"
            @click="getBonusApply(bonusNews.promo_id, 'apply')"
          >
            {{ $t('global.join') }}
          </v-btn>

          <!-- 我要領取 -->
          <v-btn
            v-if="checkBtn.redeem === 'Y'"
            fixed
            rounded
            width="160px"
            class="bonus__item-apply"
            color="secondary"
            @click="getBonusRedeem(bonusNews.promo_id, 'redeem')"
          >
            {{ $t('global.getBonus') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <!-- 彈窗 (失敗) -->
    <v-dialog
      v-model="failDialog.status"
      max-width="250"
    >
      <v-card
        class="bg d-flex flex-column"
      >
        <v-card-title class="headline">
          <div class="d-flex flex-column align-center w-100">
            <!-- FAIL ICON -->
            <Icon
              data="@icon/alert/fail.svg"
              width="24px"
              height="24px"
              class="danger--text"
            />
          </div>
          <!-- TITLE -->
          <div
            class="mt-2 subtitle-1 w-100"
            :class="{'text-center': failDialog.errors.length < 2}"
          >
            {{ failDialog.errors[0] }}
          </div>
        </v-card-title>

        <v-card-text class="px-4 pb-0">
          <ul
            v-for="(item, index) in errorMessage"
            :key="index"
          >
            <li class="caption">
              {{ item }}
            </li>
          </ul>
        </v-card-text>
        <v-spacer />
        <v-card-actions>
          <v-spacer />
          <!-- 確認 -->
          <v-btn
            slot="btn"
            class="mb-2"
            text
            color="primary"
            @click="failDialog.status = false"
          >
            {{ $t('global.confirmation') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { resStatus } from '@/utils/resUtils'
import { timezone } from '@/utils/day'
import { clickEventInterval } from '../../utils/event'

export default {
  data: () => ({
    form: {
      timezone: '',
      page: 1,
      bonus_type: 'all',
      promo_id: null,
    },

    allBonusNews: {},
    bonusNews: [],

    checkBtn: [],

    buttonText: {
      apply: '參加',
      redeem: '領取',
    },

    resLastPage: null,

    // 錯誤 彈窗
    failDialog: {
      status: false,
      errors: [
        '--', // TITLE
        '--', // 條件一
      ],
    },

    // 執行過後，是否可以再打 api
    eventLaunch: {
      apply: null,
      getBonus: null,
    },
  }),

  computed: {
    ...mapState({
      settings: state => state.websiteData.settings,
    }),
    ...mapGetters(['baseImgUrl', 'layout']),

    bonusNewId() {
      return this.$route.params.id
    },

    errorMessage() {
      return this.failDialog.errors.filter((item, index) => index !== 0) || []
    },
  },

  mounted() {
    this.form.promo_id = this.$route.params.id
    this.form.timezone = timezone()
    this.getBonusNews()
    this.checkBtnStatus()

    // 初始化 延遲打 api
    Object.keys(this.eventLaunch)
      .forEach(index => {
        this.eventLaunch[index] = clickEventInterval(this.settings.click_interval)
      })
  },

  methods: {
    ...mapActions([
      'get_bonus_news',
      'set_toolbar_title',
      'check_bonus_button',
      'get_bonus',
      'show_alert',
      'monthly_bonus_receive',
    ]),

    checkBtnSuccess(data) {
      this.checkBtn = data.button
    },

    /**
     * 確認按鈕狀態
     * @date 2021-08-02
     */
    async checkBtnStatus() {
      const res = await this.check_bonus_button(this.$route.params.id)
      resStatus(res, this.checkBtnSuccess)
    },

    async filterBonusNews(promo_id, data) {
      const filterData = {}
      for (const index in data) {
        if (data[index].promo_id === promo_id) {
          const filterData = data[index]

          this.bonusNews = filterData
          this.set_toolbar_title(this.bonusNews.title)
        }
      }
    },

    filterObject(promo_id, data) {

    },

    /**
     * 取得優惠活動 (成功)
     * @date 2021-08-02
     * @param {objecg} data
     */
    async getBonusNewsSuccess({ bonus_news: data }) {
      if (!data.data[0]) return this.$router.push({ path: '/bonus' })

      this.bonusNews = data.data[0]
      this.set_toolbar_title(this.bonusNews.title)
    },

    /**
     * 路由 promo_id 篩選不出資料的話 (轉址)
     * @date 2021-08-02
     */
    filterBonusItemFail() {
      this.$router.replace({ name: 'bonus' })
    },

    getBonusNewsFail() {
      this.show_alert({
        icon: 'fail',
      })
    },

    /**
     * 取得優惠活動內容
     * @date 2021-03-16
     * @returns {any}
     */
    async getBonusNews() {
      const res = await this.get_bonus_news(this.form)
      resStatus(res, this.getBonusNewsSuccess, this.getBonusNewsFail)
    },

    getRedeemSuccess(data) {
      this.show_alert({
        icon: 'done',
        // text: '申请成功，我们将会尽速审核',
      })
    },

    getApplySuccess(data) {
      this.show_alert({
        icon: 'done',
        // text: '提交申请',
      })
    },

    getBounsFail(errors) {
      this.show_alert({
        icon: 'fail',
        text: errors,
      })
    },

    /**
     * 取得其它頁活動資料
     * @date 2021-07-27
     * @param {number} lastPage 最後一頁
     */
    getOtherBonusNews(lastPage) {
      while (this.form.page < lastPage) {
        this.form.page++
        this.getBonusNews()
      }
    },

    /**
     * 領取 btn
     * @date 2021-03-16
     * @param {string} promo_id 促銷代碼
     * @param {string}} type 領取方式
     */
    async getBonusRedeem(promo_id, type) {
      // 狀態未初始化 不可打
      if (!this.eventLaunch.getBonus.getStatus()) return false
      // 數秒後回複狀態
      this.eventLaunch.getBonus.setStatus(false)

      const res = await this.get_bonus({ promo_id, type })
      resStatus(res, this.getBonusSuccess, this.getBounsFail)
      this.checkBtnStatus()
    },

    /**
     * 錯誤處理 (送出 簽到 / 申請 / 取領)
     * @date 2022-01-13
     * @param {array} errors
     */
    failEvent(errors) {
      this.failDialog.status = true
      this.failDialog.errors = errors
    },

    /**
     * response 響應處理
     * 如果 (成功)
     * 如果 (失敗)
     * @date 2022-01-13
     * @param {string} {data 成功的資料
     * @param {array} errors} 失敗的資料
     * @param {function} successFun 成功 執行 事件
     * @param {function} failFun 失敗 執行 事件
     */
    resDataCheck({ data, errors }, successFun, failFun) {
      if (!errors) return successFun(data, errors)
      failFun(errors)
    },

    /**
     * 參加 / 申請 btn
     * @date 2021-03-16
     * @param {string} promo_id 促銷代碼
     * @param {string} type 領取方式
     */
    async getBonusApply(promo_id, type) {
      // 狀態未初始化 不可打
      if (!this.eventLaunch.apply.getStatus()) return false
      // 數秒後回複狀態
      this.eventLaunch.apply.setStatus(false)
      const res = await this.get_bonus({ promo_id, type, timezone: this.form.timezone })

      this.resDataCheck(res, this.getApplySuccess, this.failEvent)

      this.checkBtnStatus()
    },

    monthlyBonusReceiveSuccess() {
      this.getApplySuccess()
      this.checkBtnStatus()
    },

    monthlyBonusReceiveFail(errors) {
      this.getBounsFail(errors)
      this.checkBtnStatus()
    },

    // 我要領取 (每月)
    async monthlyBonusReceive(promoId) {
      const promoData = { promo_id: promoId, timezone: this.form.timezone }

      const res = await this.monthly_bonus_receive(promoData)
      resStatus(res, this.monthlyBonusReceiveSuccess, this.monthlyBonusReceiveFail)
    },
  },
}
</script>

<style lang="scss" scoped>
.bonus__item-apply {
	bottom: 10px;
	left: 50%;
	margin-left: -80px;
}
.bonus__daily-checkin{
  bottom: 60px;
	left: 50%;
	margin-left: -80px;
}

.opacity-1 {
  opacity: .5;
}
.h-100dvh {
  height: 100dvh;
}
</style>
