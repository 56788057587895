// 使用者相關
import {
  SET_USER_DATA,
  SET_USER_LOGIN_STATUS,
  SET_USER_WALLET_TYPE,
  SET_COMPLETED_BASEINFO,
} from '../mutation-types'

import vm from '@/main'
import { resStatus } from '@/utils/resUtils'
import { getCookie, setCookie, removeCookie } from '@/utils/cookie'
import { encrypt } from '../../utils/cryp'
import {
  userLoginAPI,
  userLogoutAPI,
  userRegisterAPI,
  userDataRefreshAPI,
  editUserPasswordAPI,
  sendEmailValidateCodeAPI,
  validateEmailValidateCodeAPI,
  resetUserPasswordByNoLoginAPI,
  googleAuthLoginAPI,
  appleAuthLoginAPI,
  lineAuthLoginAPI,
  getPhoneCountryCodeAPI,
} from '@/api'

const state = () => ({
  isLogin: false,
  userData: [],

  phoneCountryCodeList: [],
  completedBaseInfo: false,
})

const getters = {}

const mutations = {
  SET_PHONE_COUNTRY_CODE(state, payload) {
    state.phoneCountryCodeList = payload.list
  },

  /**
   * 儲存使用者資料
   * @date 2021-03-08
   * @param {String} state userData
   * @param {Object} payload 使用者所有資料
   */
  [SET_USER_DATA](state, payload) {
    state.userData = payload
  },

  /**
   * 設定使用者登入狀態
   * @date 2021-03-08
   * @param {string} state isLogin
   * @param {boolean} loginStatus 是否登入狀態 true false
   */
  [SET_USER_LOGIN_STATUS](state, loginStatus) {
    state.isLogin = loginStatus
  },

  [SET_USER_WALLET_TYPE](state, walletType) {
    state.userData.member.wallet_type = walletType
  },

  [SET_COMPLETED_BASEINFO](state, baseInfoStatus) {
    state.completedBaseInfo = baseInfoStatus
  },
}

const actions = {
  /**
   * 設定使用者資料：
   * 1 移除原 cookie 使用者 token
   * 2 設定使用者最新 token
   * 3 儲存使用者 cookie -> store
   * 4 設定使用者登入狀態 (true)
   * 5 儲存使用者 cookie -> session
   * @date 2021-03-08
   * @param {string} {commit}
   * @param {Object} userData 使用者所有資料
   */
  set_user_data({ commit }, userData) {
    removeCookie('accessToken')
    setCookie('accessToken', userData.access_token)

    commit('SET_USER_DATA', userData)
    commit('SET_USER_LOGIN_STATUS', true)
    sessionStorage.setItem('userData', JSON.stringify(userData))

    // 如果「記住我」的話，刷新也存一份
    if (localStorage.getItem('userData')) {
      localStorage.setItem('userData', JSON.stringify(userData))
    }
  },

  /**
   * 重置使用者資料
   * @date 2021-09-22
   * @param {any} commit
   */
  reset_user_data({ commit }) {
    removeCookie('accessToken')
    commit('SET_USER_DATA', [])
    commit('SET_USER_LOGIN_STATUS', false)
    commit('RESET_LINE_DISPLAY_NAME_AND_AVATAR')

    // 中心 / 鎖定 錢包 歸零
    commit('SET_MAIN_BALANCE', 0)
    commit('SET_LOCKED_BALANCE', 0)

    sessionStorage.removeItem('userData')
    localStorage.removeItem('userData')
  },

  /**
   * <記住我> 記住使用者資料 (localStorage)
   * - 儲存 localStorage
   * @date 2021-03-08
   * @param {string} context
   * @param {Object} userData 使用者資料
   */
  remember_user_data(context, userData) {
    localStorage.setItem('userData', JSON.stringify(userData))
  },

  /**
   * 儲存使用者 帳號 / 密碼 (localStorage)
   * @date 2021-09-22
   * @param {any} context
   * @param {any} userAccPwd 使用者 帳號/密碼
   */
  remember_user_acc_pwd(context, userAccPwd) {
    localStorage.setItem('IPH_AP', encrypt(JSON.stringify(userAccPwd)))
  },

  /**
   * 移除 使用者 帳號 / 密碼 (localStorage)
   * @date 2021-09-22
   * @param {any} context
   */
  remove_remember_user_acc_pwd(context) {
    localStorage.removeItem('IPH_AP')
  },

  async set_user_session_data({ dispatch }, userData) {
    dispatch('set_user_data', userData)
  },

  /**
   * 使用者登入
   * - 設置使用者資料
   * - 打 api
   * - 如果登入成功 -> 使用者登入成功
   * - 如果<記住我> -> 儲存 使用者資料
   * - 如果<記住我> -> 儲存 使用者帳密
   * @date 2021-03-08
   * @param {string} {dispatch}
   * @param {Object} form
   * @returns {josn} 使用者所有資料
   */
  async user_login({ dispatch }, form) {
    const userData = {
      account: form.account,
      password: form.password,
    }

    const resData = await userLoginAPI(userData)
    if (!resData) return false

    resStatus(resData, data => {
      dispatch('set_user_data', data)

      // 如果此次無「記住我」 且 之前有「記住我」 => 移除使用者資料
      if (!form.remember && localStorage.getItem('IPH_AP')) dispatch('remove_remember_user_acc_pwd')

      // 如果「記住我」
      if (form.remember) {
        dispatch('remember_user_data', data)
        dispatch('remember_user_acc_pwd', userData)
      }
    })
    return resData
  },

  /**
   * 使用者資料更新
   * @date 2021-10-26
   * @param {any} {dispatch}
   */
  async user_data_refresh({ dispatch }) {
    const res = await userDataRefreshAPI()
    resStatus(res, data => dispatch('set_user_data', data))
    return res
  },

  /**
   * 使用者登出
   * @date 2021-03-09
   * @param {any} {dispatch}
   * @returns {string} 登出信息
   */
  async user_logout({ dispatch }) {
    const res = await userLogoutAPI()
    resStatus(res, () => {
      dispatch('reset_user_data')
      // 重新取得 客服 url
      dispatch('get_service_url')
    })
    return res
  },

  /**
   * 使用者註冊
   * @date 2021-03-15
   * @param {any} {dispatch}
   * @param {any} form 註冊表單
   * @returns {any} 結果
   */
  async user_register({ dispatch }, form) {
    const website = await dispatch('set_website')
    form.website = website
    const res = await userRegisterAPI(form)
    return res
  },

  /**
   * 使用者修改 (登入) 密碼
   * @date 2021-12-27
   * @param {object} context vuex
   * @param {string} {password 使用者 (原) 密碼
   * @param {string} newPassword 使用者 (新) 密碼
   * @param {string} newPasswordConfirm} 使用者 (新) 密碼 再次確認
   * @returns {object} 響應狀態
   */
  async modify_user_password(context, { password, newPassword, newPasswordConfirm }) {
    const passwordData = {
      password,
      new_password: newPassword,
      new_password_confirmation: newPasswordConfirm,
    }

    try {
      const resData = await editUserPasswordAPI(passwordData)
      return resData
    } catch {
      // 例外處理
    }
  },

  async send_email_validate_code(context, { email }) {
    try {
      const sendStatus = await sendEmailValidateCodeAPI({ email })
      return sendStatus
    } catch {
      console.log('email 發送失敗')
    }
  },

  async send_email_validate_code_not_login(context, userData) {
    try {
      const sendStatus = await sendEmailValidateCodeAPI(userData)
      return sendStatus
    } catch {
      console.log('email 發送失敗')
    }
  },

  async validate_email_validate_code(context, { email, validateCode }) {
    try {
      const formData = {
        email,
        code: validateCode,
      }
      const resData = await validateEmailValidateCodeAPI(formData)
      return resData
    } catch {
      console.log('EMAIL 驗証失敗')
    }
  },

  async reset_user_password_by_no_login(context, data) {
    try {
      const res = await resetUserPasswordByNoLoginAPI(data)
      return res
    } catch {
      console.log('重設密碼失敗(無登入狀態)')
    }
  },

  async google_sign_in(context, data) {
    const res = await googleAuthLoginAPI(data)
    return res
  },

  async apple_sign_in(content, idToken) {
    const res = await appleAuthLoginAPI(idToken)
    return res
  },

  async line_login({ commit }, data) {
    const res = await lineAuthLoginAPI(data)

    if (res.status === '1') {
      commit('SET_COMPLETED_BASEINFO', res.data.member.completed_baseinfo === 'Y')
    }
    return res
  },

  async get_phone_country_code({ commit }) {
    try {
      const res = await getPhoneCountryCodeAPI()

      resStatus(res, (data) => {
        commit('SET_PHONE_COUNTRY_CODE', data)
      })
    } catch (error) {
      console.log('Error: get_phone_country_code', error)
    }
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
