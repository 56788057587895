<template>
  <div>
    <component :is="layoutTemplate" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { curryTemplate } from '../../utils/template'

import template1 from './template/BottomNavTemplate1.vue'
import template3 from './template/BottomNavTemplate3.vue'
import template4 from './template/BottomNavTemplate4.vue'
import template5 from './template/BottomNavTemplate5.vue'

import Mode1 from './template/BottomNavMode1.vue'
import Mode2 from './template/BottomNavMode2.vue'

export default {
  components: {},

  computed: {
    ...mapGetters(['layout', 'bottomNavType']),

    layoutTemplate() {
      const templateObj = {
        template1,
        template3,
        template4,
      }
      if (this.bottomNavType === 'mode1') return Mode1
      if (this.bottomNavType === 'mode2') return Mode2
      if (this.layout === 'template5') return template5
      return curryTemplate(this.layout, templateObj)
    },
  },

  mounted() {
    console.log(this.bottomNavType) // 樣式一 五小 樣式二 四小一大
    console.log('20231002 13:56')
  },
}
</script>

<style lang="scss" scoped>

</style>
