<template>
  <v-app :class="style">
    <!-- 設置背景色 -->
    <v-main class="bg">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import isLocalDev from './utils/isLocalDev'
import { deviceCheck } from './utils/device'
import VConsole from 'vconsole'
import { parseUrlQuery } from './utils/liff'

import pageConfig from '../package.json'

export default {
  name: 'App',

  data: () => ({
    data: [],
  }),

  computed: {
    ...mapGetters(['baseImgUrl', 'isLiffMode']),
    ...mapState({
      style: state => state.style,
      favicon: state => state.websiteData.site_params?.favicon,
    }),
    device() {
      return deviceCheck()
    },
  },

  mounted() {
    this.$log(process.env.NODE_ENV, process.env.VUE_APP_THEME, process.env.VUE_APP_API)

    // vConsole 顯示 (本地開發 && 行動裝置) 或 (url 加上 ?log=true)
    if ((this.device !== 'all' && isLocalDev()) || parseUrlQuery(location.search).log === 'true') {
      const vConsole = new VConsole({ theme: 'dark' })
    }

    this.$log(`--- v${pageConfig.version} ---`)
    this.$log(`Liff Mode(getters): ${this.isLiffMode}`)
  },
}
</script>

<style lang="scss" scoped></style>
