// MUTACTION 常數

// 使用者相關
export const SET_USER_DATA = 'SET_USER_DATA' // 設定使用者所有資料
export const SET_USER_LOGIN_STATUS = 'SET_USER_LOGIN_STATUS' // 設定使用者登入狀態
export const SET_USER_WALLET_TYPE = 'SET_USER_WALLET_TYPE' // 設定使用者錢包模式
export const SET_COMPLETED_BASEINFO = 'SET_COMPLETED_BASEINFO' // 設定使用者個人資料是否完善

// WEBSITE
export const SET_WEBSITE_DATA = 'SET_WEBSITE_DATA' // 網站基本資料設置
export const SET_THEME_STYLE = 'SET_THEME_STYLE' // 設置網站 主題色與深淺模式
export const SET_INDEX_LAYOUT_AREA = 'SET_INDEX_LAYOUT_AREA' // 設置首頁版塊
export const SET_WEB_DOCUMENT = 'SET_WEB_DOCUMENT' // 設置網站文件
export const SET_SERVICE_URL = 'SET_SERVICE_URL' // 設置客服 url

// dialog
export const SET_ALERT_DATA = 'SET_ALERT_DATA' // 警示彈窗內容設置
export const SET_ALERT_STATUS = 'SET_ALERT_STATUS' // 警示彈窗狀態設置
export const SET_PROGRESS_STATUS = 'SET_PROGRESS_STATUS' // 進度條狀態設置

// TOOLBAR
export const SET_TOOLBAR_TITLE = 'SET_TOOLBAR_TITLE' // 設置 toolbar title

// userData
export const SET_VIP_DATA = 'SET_VIP_DATA' // 設置 vip 相關資料
export const SET_USER_BASE_INFO = 'SET_USER_BASE_INFO' // 設置使用者基本資料

// news
export const SET_NEWS_DATA = 'SET_NEWS_DATA' // 設置 消息中心 資料
export const SET_PROCLAMATION_DATA = 'SET_PROCLAMATION_DATA' // 設置 公告 資料
export const PUSH_NEWS_DATA = 'PUSH_NEWS_DATA' // 設置 公告 資料
export const SET_MEMBER_NEWS = 'SET_MEMBER_NEWS' // 設置 站內信 資料
export const PUSH_MEMBER_NEWS = 'PUSH_NEWS_DATA' // push 站內信 資料

// service && App 相關
export const SET_APPS_DATA = 'SET_APPS_DATA' // 設置 app 相關資料
export const SET_WEBVIEW_DATA = 'SET_WEBVIEW_DATA' // 設置 webview (service) 資料
export const SET_APP_DOWNLOAD_TYPE = 'SET_APP_DOWNLOAD_TYPE' // 設置 App提供下載類型資料，動態顯示 App選單
