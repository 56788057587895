import Vue from 'vue'
import Vuex from 'vuex'
import vm from '../main'
import hexToRgba from 'hex-to-rgba'

import user from './modules/user'
import dialog from './modules/dialog'
import toolbar from './modules/toolbar'
import bonus from './modules/bonus'
import record from './modules/record'
import userData from './modules/userData'
import wallet from './modules/wallet'
import bank from './modules/bank'
import transaction from './modules/transaction'
import transfer from './modules/transfer'
import vip from './modules/vip'
import news from './modules/news'
import personal from './modules/personal'
import recommend from './modules/recommend'
import slot from './modules/slot'
import layout from './modules/layout'
import service from './modules/service'

import { resStatus } from '../utils/resUtils'
import isLocalDev from '../utils/isLocalDev'
import settingTheme from '../utils/settingTheme'
import { deviceCheck } from '../utils/device'
import { isMyCardService } from '../utils/system'
import { detectDevIsLineLiffMode } from '../utils/liff'

import {
  SET_WEBSITE_DATA,
  SET_THEME_STYLE,
  SET_INDEX_LAYOUT_AREA,
  SET_WEB_DOCUMENT,
  SET_SERVICE_URL,
} from './mutation-types'

import {
  getWtokenAPI,
  getSiteInfoAPI,
  getWebsiteStyleAPI,
  getWebsiteIndexLayoutAPI,
  getDocumentDetailsAPI,
  getDocumentAPI,
  getServiceUrlAPI,
  getAppDownloadUrlAPI,
  getDTListDataAPI,
  getNavbarAPI,
  qrcodeLoginAPI,
} from '../api'

Vue.use(Vuex)

const state = () => ({
  websiteData: [],
  theme: '',
  style: '',
  indexPageLayoutArea: [],

  document: [],

  // 客服 url
  serviceUrl: null,

  statusCodeError: null,

  // social auth client ID
  clientID: {
    google: '',
    apple: '',
  },

  // LINE
  line: {
    liff: {
      // id: '1657672659-M12dnXK6',
      displayName: null,
      avatar: null,
      status: null,
    },
  },

  userInfo: {
    mainBalanceProgressStatus: false,
  },
})

const getters = {
  layout: state => state.websiteData.site_params.template,
  baseImgUrl: state => state.websiteData.image_url,
  websiteLogo: state => state.websiteData.site_params?.logo_app || state.websiteData.site_params?.logo_dark,
  websiteName: state => state.websiteData.site_params?.site_name,
  lang: state => state.websiteData.lang, // thai china vn
  clientLang: () => vm.$i18n.locale, // 前端當前語系
  nation: state => state.websiteData?.nation,
  allowMemberChangePassword: state => state.websiteData.func_switch.allow_member_change_password,
  dynamicAgentDisplay: state => state.websiteData.func_switch.section_agent_display,
  docs: state => docName =>
    state.document.filter(item => item.name === docName)[0],
  theme: state => state.theme,
  style: state => state.style,
  gradientBg: state => {
    const { background2_1, background2_2 } = state.websiteData.site_params.color_set
    const color1 = background2_1.includes('#') ? hexToRgba(background2_1) : background2_1
    const color2 = background2_2.includes('#') ? hexToRgba(background2_2) : background2_2
    return `background: linear-gradient(${color1}, ${color2});`
  },
  customGradientBgTypeA: state => {
    const { background2_1, background2_2 } = state.websiteData.site_params.color_set
    const color1 = background2_1.includes('#') ? hexToRgba(background2_1) : background2_1
    const color2 = background2_2.includes('#') ? hexToRgba(background2_2) : background2_2
    return `background: linear-gradient(-258deg, ${color1}, ${color2});`
  },
  // 營商幣值符號 lang
  currencySymbol: state => state.websiteData.currency,
  // 貨幣符號
  currencySymbol_: state => state.websiteData.currency_symbol,
  // 裝置
  currentDevice: () => deviceCheck() || '',

  // 第三方登入是否顯示
  socialDisplay: state => state.websiteData.func_switch.social_login_display,
  // google 登入顯示
  googleSignInDisplay: state => state.websiteData.func_switch.google_login_display,
  appleSignInDisplay: state => state.websiteData.func_switch.apple_login_display,

  // ------------- 第三方登入 (google / apple) --------------------------------
  googleClientID: state => state.clientID.google,
  appleClientID: state => state.clientID.apple,

  // 當前「版型」 https://giocoplus.gf-gaming.com/admin/sys-template
  // (版型一 'iphd') (版型二 'thgi') (版型三 'template3') (版型四 'template4')
  currentThemeType: state => state.websiteData.site_params.template,

  // line liff id
  lineLiffId: state => state.line.liff.id,
  // myCard 金流網站
  isMycardDomainService: () => isMyCardService(location.host),

  // liff model
  isLiffMode: () => detectDevIsLineLiffMode() || window.liff.isInClient(),

  // BottomNav 導覽列 版型判斷
  bottomNavType: state => state.websiteData.site_params.h5_menubar_mode,
}

const mutations = {
  SET_USERINFO_BALANCE_PROGRESS_STATUS(state, status) {
    state.userInfo.mainBalanceProgressStatus = status
  },

  // 保存 liff 狀態
  SET_LIFF_STATUS(state, isLiffMode) {
    state.line.liff.status = isLiffMode
  },
  // 保存 line 資料
  SET_LINE_DISPLAY_NAME_AND_AVATAR(state, { displayName, pictureUrl }) {
    state.line.liff.displayName = displayName
    state.line.liff.avatar = pictureUrl
  },
  RESET_LINE_DISPLAY_NAME_AND_AVATAR(state, payload) {
    state.line.liff.displayName = null
    state.line.liff.avatar = null
  },
  SET_STATUS_ERROR_CODE(state, errorCode) {
    state.statusCodeError = errorCode
  },
  [SET_WEBSITE_DATA](state, websiteData) {
    state.websiteData = websiteData

    const url = new URL(location.href)
    const search = new URLSearchParams(url.search)
    const currentTemplate = search.get('template')

    if (currentTemplate) {
      state.websiteData.site_params.template = currentTemplate
      console.log(`本地切換，模版:  ${currentTemplate}`)
    }

    // 設置 title favicon
    document.querySelector('#title').text = state.websiteData.site_params.site_name
    document.querySelector('#favicon').href = state.websiteData.image_url + state.websiteData.site_params.favicon + '?v=2'
  },

  [SET_THEME_STYLE](state, { theme, style }) {
    state.theme = theme
    state.style = style
  },

  [SET_INDEX_LAYOUT_AREA](state, layoutArea) {
    state.indexPageLayoutArea = layoutArea
  },

  [SET_WEB_DOCUMENT](state, docs) {
    state.document = docs
  },

  [SET_SERVICE_URL](state, data) {
    if (!data) state.serviceUrl = null
    else state.serviceUrl = data.chatbox_url
  },

  SET_SOCIAL_AUTH_CLIENT_ID({ clientID }, { apple_client_id, google_client_id }) {
    clientID.google = google_client_id
    clientID.apple = apple_client_id
  },
}

const actions = {
  set_status_error_code({ commit }, statusErrorCode) {
    commit('SET_STATUS_ERROR_CODE', statusErrorCode)
  },

  // 儲存 wtoken lang 語系設定
  set_wtoken_lang(context, resData) {
    sessionStorage.setItem('wtoken', resData.wtoken)
    sessionStorage.setItem('lang', resData.lang)

    // client 切換語系
    const clientLang = sessionStorage.getItem('clientLang')
    if (clientLang) {
      vm.$i18n.locale = clientLang
      return false
    }

    // api 設定語系
    vm.$i18n.locale = resData.lang
    // vm.$i18n.locale = 'vi-VN' // 越南語系
  },

  set_line_user_data({ commit }, lineProfile) {
    commit('SET_LINE_DISPLAY_NAME_AND_AVATAR', lineProfile)
  },

  // website 設定
  set_website() {
    // 是否本地開發
    const website = isLocalDev()
      ? process.env.VUE_APP_WEBSITE.replace('m.', '').replace('v2.', '').replace(':8080.', '')
      : location.host.replace('m.', '').replace('v2.', '')
    console.error('set_website', website)

    return website
  },

  /**
   * 取得網站 wtoken 語系
   * @date 2021-03-12
   * @param {any} {dispatch}
   * @param {any} payload
   */
  async get_web_token({ dispatch }, payload) {
    const website = await dispatch('set_website')
    const resData = await getWtokenAPI({ website })
    console.error('get_web_token', website)
    if (resData.wtoken) {
      dispatch('set_wtoken_lang', resData)
      if (resData.client_ids) dispatch('set_social_media_auth_client_id', resData)
      return resData
    } else return false
  },

  set_social_media_auth_client_id({ commit }, { client_ids }) {
    commit('SET_SOCIAL_AUTH_CLIENT_ID', client_ids)
  },

  /**
   * 取得網站基本資料 (成功)
   * @date 2021-07-21
   * @param {object} {commit}
   * @param {object} websiteData 網站資料
   */
  set_website_data({ dispatch, commit }, websiteData) {
    commit(SET_WEBSITE_DATA, websiteData)
    dispatch('set_apps_data', websiteData.cs_profile.apps)
    dispatch('set_webview_data', websiteData.cs_profile)
    dispatch('set_app_download_type', websiteData.app_download_type)
  },

  /**
   * 取得網站基本資料
   * @date 2021-03-09
   * @param {any} {commit}
   * @param {fun}} fetch_getSiteInfoAPI = 取得網站 api
   * @returns {object} 網站資料
   */
  async get_website_data({ dispatch }, fetch_getSiteInfoAPI = getSiteInfoAPI) {
    try {
      const websiteData = await fetch_getSiteInfoAPI()
      resStatus(
        websiteData,
        // status === 1
        data => {
          dispatch('set_website_data', data)
          return data
        },
        // status === 0
        errors => {
          console.log(errors)
        },
      )
    } catch (e) {
      vm.$log('DEBUG: 取得網站基本資料 API 錯誤 (index/store)')
    }
  },

  /**
   * 換主題色 && 深淺模式 api
   * @date 2021-03-10
   * @param {any} context vuex
   */
  async get_website_theme({ dispatch, commit }) {
    try {
      const { data } = await getWebsiteStyleAPI()
      let theme = data.mode
      let style = data.style

      // (手動切換)開發完 app 還原
      // const theme = 'dark'
      // const style = 'darkGD'
      const url = new URL(location.href)
      const search = new URLSearchParams(url.search)
      const currentStyle = search.get('style')
      const currentTheme = search.get('theme')

      if (currentStyle) {
        style = currentStyle
        console.log(`本地切換，主色: ${style}`)
      }
      if (currentTheme) {
        theme = currentTheme
        console.log(`本地切換，模式:  ${currentTheme}`)
      }
      settingTheme(theme, style)
      commit('SET_THEME_STYLE', { theme, style })
      // api 失敗
    } catch (error) {
      // alert(error.message)
      // alert(theme)
      // alert(style)

      const theme = process.env.VUE_APP_THEME // 'dark'
      const style = process.env.VUE_APP_TYPE // 'darkGO'

      settingTheme(theme, style)
      commit('SET_THEME_STYLE', { theme, style })

      // alert(error)
      // alert(theme)
      // alert(style)
      if (!detectDevIsLineLiffMode() || !window.liff.isInClient()) {
        dispatch('show_alert', {
          icon: 'fail',
          text: '套色失敗',
        })
      }
    }
  },

  /**
   * 首頁版塊 api
   * @date 2021-03-19
   * @param {any} {commit}
   */
  async get_homepage_layout_area({ commit, dispatch }) {
    const { data, errors } = await getWebsiteIndexLayoutAPI()

    // 沒有設置 layout 版塊
    if (errors) {
      dispatch('show_alert', {
        icon: 'fail',
        text: errors[0],
      })
      return false
    }

    commit('SET_INDEX_LAYOUT_AREA', data.layout)
  },

  /**
   * 取得網站資料
   * @date 2021-05-10
   * @param {object} {commit}  上下文
   */
  async get_doc({ commit }) {
    const res = await getDocumentAPI()
    commit(SET_WEB_DOCUMENT, res.docs)
  },

  /**
   * 取得文件 (依類型)
   * @date 2021-05-11
   * @param {any} {commit}
   * @param {any} type 類型 類型，若不給此參數，API會吐出所有資料。常见问题 faq、新手幫助 help、企业事务 business、联系我们 contact
   * @returns {any}
   */
  async get_type_doc({ commit }, type) {
    const res = await getDocumentDetailsAPI(type)
    return res
  },

  /**
   * 取得客服連結
   * 若有 => 寫入
   * 若無 => 寫入 null
   * @date 2021-09-24
   * @param {any} {commit}
   */
  async get_service_url({ commit }) {
    const res = await getServiceUrlAPI()
    resStatus(res, (data) => commit(SET_SERVICE_URL, data), () => commit(SET_SERVICE_URL, null))
  },

  async get_app_download_url(context, website) {
    const res = await getAppDownloadUrlAPI(website)
    return res
  },

  async get_dt_list_data() {
    const res = await getDTListDataAPI()
    return res
  },

  async get_nav_bar() {
    const res = await getNavbarAPI()
    return res
  },

  async qrcode_login(context, token) {
    const res = await qrcodeLoginAPI(token)
    return res
  },
}

export default new Vuex.Store({
  // 嚴格模式
  strict: process.env.NODE_ENV !== 'production',

  // 套件
  // plugins: [createPersistedState()],

  state,
  mutations,
  actions,
  getters,

  modules: {
    user,
    dialog,
    toolbar,
    bonus,
    record,
    userData,
    wallet,
    bank,
    transaction,
    transfer,
    vip,
    news,
    personal,
    recommend,
    slot,
    layout,
    service,
  },
})
