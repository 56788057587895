import Vue from 'vue'
import store from '../store'
import VueRouter from 'vue-router'
import vm from '../main'

import routes from './routes'

import { parseUrlQuery, getLiffIDToken, detectDevIsLineLiffMode } from '../utils/liff'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
})

// -------------- BEFORE -------------- //

router.beforeEach(async(to, from, next) => {
  const errorPages = [503, 500]

  if (errorPages.includes(store.state.statusCodeError) && !errorPages.includes(Number(to.name))) return next({ name: store.state.statusCodeError + '' })

  // 進度條 ON
  await store.dispatch('set_progress_status', true)
  if (!store.state.statusCodeError) {
    // 取網站 wtoken
    await store.dispatch('get_web_token')

    // 如果 liff 模式
    if (detectDevIsLineLiffMode() || window.liff.isInClient()) {
      if (!window.liff.isLoggedIn()) {
        window.liff.login()
      }
      // 如果 LIFF 已登入 (但系統尚未登入)
      if (window.liff.isLoggedIn() && !store.state.user.isLogin) {
        getLiffIDToken(parseUrlQuery(location.search), next)
      }
    }

    // 取得網站資料
    await store.dispatch('get_website_data')

    // 如果 記住我
    const isRemember = JSON.parse(localStorage.getItem('userData'))
    if (isRemember) store.dispatch('set_user_data', isRemember)

    // 如果已經登入
    const isLogin = JSON.parse(sessionStorage.getItem('userData'))
    if (isLogin) store.dispatch('set_user_session_data', isLogin)
    else store.dispatch('reset_user_data')

    // 如果不在 liff 模式
    if (!detectDevIsLineLiffMode() || !window.liff.isInClient()) {
      // 需要登入後才可以進入的頁面
      if (to.meta.requiresAuth && !isLogin) {
      // 進度條 ON
        store.dispatch('set_progress_status', false)

        store.dispatch('show_alert', {
          icon: 'fail',
          text: vm.$t('flashMessage.loginFirst'),
        })

        return next({ path: '/login' })
      }
    } else {
      // 如果已經登入
      if (window.liff.isLoggedIn()) {
        store.dispatch('set_progress_status', false)
        return next()
      }
    }
  }

  // 進度條 ON
  store.dispatch('set_progress_status', false)

  next()
})

export default router
