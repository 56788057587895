import { render, staticRenderFns } from "./BottomNav.vue?vue&type=template&id=de484f58&scoped=true&"
import script from "./BottomNav.vue?vue&type=script&lang=js&"
export * from "./BottomNav.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "de484f58",
  null
  
)

export default component.exports